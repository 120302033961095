/**
 * Returns the Bindplane version
 *
 * @returns the Bindplane version from the global variable, or "unknown" if it is not set
 */
export function version(): string {
  try {
    return __BINDPLANE_VERSION__;
  } catch (err) {
    return "unknown";
  }
}

export function edition(): string {
  try {
    return __EDITION__;
  } catch (err) {
    return "unknown";
  }
}

const licenseDisplayNameMap: Record<string, string> = {
  Enterprise: "Bindplane Enterprise",
  Free: "Bindplane (Free Edition)",
  FreeCloud: "Bindplane (Free Edition)",
  Google: "Bindplane (Google Edition)",
  GoogleEnterprise: "Bindplane Enterprise (Google Edition)",
  Honeycomb: "Honeycomb Telemetry Pipeline",
};

export function licenseDisplayName(license: string): string {
  return licenseDisplayNameMap[license] ?? license;
}
