import { gql } from "@apollo/client";
import {
  Autocomplete,
  BaseSelectProps,
  MenuItem,
  StandardSelectProps,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  GetAgentTypesQuery,
  useGetAgentTypesQuery,
} from "../../graphql/generated";
import styles from "./agent-type-select.module.scss";

gql`
  query GetAgentTypes {
    agentTypes {
      agentTypes {
        metadata {
          id
          displayName
          name
          version
        }
      }
    }
  }
`;

type AgentType = NonNullable<GetAgentTypesQuery["agentTypes"]["agentTypes"][0]>;

const EmptyAgentType: AgentType = {
  metadata: { id: "", displayName: "", name: "", version: 0 },
};

type SelectProps = Omit<
  StandardSelectProps & BaseSelectProps<string>,
  "variant"
>;

interface AgentTypeSelectProps extends SelectProps {
  onAgentTypeSelected: (value: string) => void;
}

export const AgentTypeSelect: React.FC<AgentTypeSelectProps> = ({
  onAgentTypeSelected,
  size,
}) => {
  const [defaultOption, setDefaultOption] = useState<AgentType>(EmptyAgentType);

  const { data } = useGetAgentTypesQuery({
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data.agentTypes.agentTypes.length > 0) {
        // we can safely assume this agent type will be present, since it is a server agent type
        // otherwise in the event it isn't, just use the first agent type returned
        const v1 = data.agentTypes.agentTypes.find(
          (agentType) => agentType.metadata.name === "observiq-otel-collector",
        );
        setDefaultOption(v1 ?? data.agentTypes.agentTypes[0]);
        onAgentTypeSelected(defaultOption.metadata.name);
      }
    },
  });
  const agentTypesData = data?.agentTypes.agentTypes ?? [];

  return (
    <Autocomplete
      id="agent-type-select"
      size={size}
      disableClearable
      value={defaultOption.metadata}
      onChange={(_, value) => {
        setDefaultOption(
          agentTypesData.find((a) => a.metadata.name === value.name) ??
            EmptyAgentType,
        );
        onAgentTypeSelected(value.name);
      }}
      options={agentTypesData.map((a: AgentType) => a.metadata)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.displayName ?? option.name}
      classes={{ root: styles.root }}
      renderInput={(params) => <TextField {...params} label={"Agent Type"} />}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <MenuItem
            {...optionProps}
            key={key}
            value={option.name}
            classes={{ root: styles.item }}
          >
            {option.displayName}
          </MenuItem>
        );
      }}
    />
  );
};
