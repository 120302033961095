import { Grid2 as Grid } from "@mui/material";
import { Typography } from "@mui/material";

interface SubHeaderProps {
  label: string;
  fontWeight?: "bold" | "normal";
}

export const ParamSubHeader: React.FC<SubHeaderProps> = ({
  label,
  fontWeight = "bold",
}) => {
  return (
    <Grid size={{ xs: 12 }}>
      <Typography fontWeight={fontWeight}>{label}</Typography>
    </Grid>
  );
};
