import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  Popper,
  PopperProps,
  Stack,
} from "@mui/material";
import { isFunction } from "lodash";
import React, { memo, useRef, useState } from "react";
import { Suggestion } from "../../graphql/generated";
import colors from "../../styles/colors";
import { validateStringAsBPLabelKey } from "../../utils/forms/validate-name-field";
import { ReturnIcon } from "../Icons";
import { SearchInput } from "./input";
import mixins from "../../styles/mixins.module.scss";
import styles from "./search-bar.module.scss";

type AutocompleteValue = Suggestion | string;

const AUTOCOMPLETE_CLASSES = {
  root: mixins["mb-1"],
  listbox: styles.listbox,
  popper: styles.popper,
  option: styles.option,
  paper: styles.paper,
};

interface SearchBarProps {
  suggestions?: Suggestion[] | null;
  suggestionQuery?: string | null;
  initialQuery?: string;
  filterOptions?: Suggestion[];
  placeholder?: string;

  // callback called when the value of the autocomplete component changes, typically on enter
  onQueryChange: (v: string) => void;

  // If true, the search bar will clear the search bar when a valid key:value is selected
  clearOnValueSelect?: boolean;
  // callback called when a valid key:value is selected
  onValueSelect?: (v: string) => void;
  // if true, the search bar will only allow one token to be entered, i.e. no spaces
  limitOneToken?: boolean;

  // if true, the search bar will display an error state if the value entered
  // onAutocompleteChange is not a valid BindPlane OP label.
  validateAsLabel?: boolean;

  autofocus?: boolean;

  readOnly?: boolean;

  // used for RolloutStagesInput to match height with other elements
  tall?: boolean;

  // used for RolloutStagesInput to render the stage labels under this component
  labels?: {
    labelsToRender: { [key: string]: string };
    labelsIndex: number;
    onDeleteLabel: (key: string, index: number) => void;
  };
}

const SearchBarComponent: React.FC<SearchBarProps> = ({
  filterOptions,
  suggestions,
  suggestionQuery,
  initialQuery,
  onQueryChange,
  placeholder,
  clearOnValueSelect,
  onValueSelect,
  limitOneToken,
  validateAsLabel,
  autofocus,
  readOnly,
  tall,
  labels,
}) => {
  const popperElRef = useRef<HTMLSpanElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState(initialQuery ?? "");
  const [error, setError] = useState(false);

  function handleInputChange(
    _e: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) {
    if (reason === "input") {
      if (error) {
        setError(false);
      }

      if (value.endsWith(" ") && limitOneToken) {
        return;
      }
      onQueryChange(value);
      setQuery(value);
    }
  }

  function handleAutocompleteChange(
    _e: React.SyntheticEvent,
    suggestion: AutocompleteValue,
  ) {
    const value =
      typeof suggestion === "string" ? suggestion : suggestion.query;
    onQueryChange(value);

    const isValueSelect = stringIsKeyValue(value);
    if (isValueSelect && onValueSelect) {
      const trimmedValue = value.trim();
      if (validateAsLabel && !isValidLabel(trimmedValue)) {
        setError(true);
        return;
      }

      onValueSelect(trimmedValue);
    }

    if (isValueSelect && clearOnValueSelect) {
      setQuery("");
      setError(false);
      return;
    }

    setQuery(value);
  }

  function handleFilterClick(query: string) {
    setQuery(query);
    onQueryChange(query);
  }

  function renderSearchInput(params: AutocompleteRenderInputParams) {
    return (
      <>
        <SearchInput
          filterOptions={filterOptions}
          inputValue={query}
          popperElRef={popperElRef}
          onFilterClick={handleFilterClick}
          placeholder={placeholder}
          error={error}
          autofocus={autofocus}
          tall={tall}
          endAdornment={
            validateAsLabel && isValidLabel(query) ? <EnterChip /> : <></>
          }
          {...params}
        />
        {labels && Object.keys(labels.labelsToRender).length > 0 && (
          <Stack direction={"row"} flexWrap={"wrap"} marginTop={1}>
            {Object.entries(labels.labelsToRender).map(([key, val]) =>
              readOnly ? (
                <Chip
                  key={`label-chip-row-${labels.labelsIndex}-${key}:${val}`}
                  data-testid={`label-chip-row-${labels.labelsIndex}-${key}:${val}`}
                  size="small"
                  label={`${key}: ${val}`}
                  disabled
                  style={{ margin: "0 4px 4px 0" }}
                />
              ) : (
                <Chip
                  key={`label-chip-row-${labels.labelsIndex}-${key}:${val}`}
                  data-testid={`label-chip-row-${labels.labelsIndex}-${key}:${val}`}
                  size="small"
                  label={`${key}: ${val}`}
                  style={{ margin: "0 4px 4px 0" }}
                  onDelete={() =>
                    isFunction(labels.onDeleteLabel) &&
                    labels.onDeleteLabel(key, labels.labelsIndex)
                  }
                />
              ),
            )}
          </Stack>
        )}
      </>
    );
  }

  // It's unclear why, but we need to override the style here
  // for the popper to position correctly.
  function renderPopper(props: PopperProps) {
    // Only render the popper if we have suggestions and the autocomplete is open
    if (!open || filteredSuggestions.length === 0) {
      return null;
    }

    return popperElRef.current ? (
      <Popper {...props} style={{}} anchorEl={popperElRef.current} />
    ) : null;
  }

  const filteredSuggestions = relevantSuggestions(
    query,
    suggestions,
    suggestionQuery,
  );

  return (
    <Autocomplete
      classes={AUTOCOMPLETE_CLASSES}
      onClose={(_e: React.SyntheticEvent, reason) => {
        if (reason === "selectOption") {
          return;
        }
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      options={filteredSuggestions}
      autoHighlight
      inputValue={query}
      size="small"
      getOptionLabel={(s: Suggestion | string) => {
        if (typeof s === "object") {
          return s.label;
        } else {
          return s;
        }
      }}
      freeSolo
      disableClearable
      // Overrides the autocomplete behavior
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onChange={handleAutocompleteChange}
      renderInput={renderSearchInput}
      PopperComponent={renderPopper}
      disabled={readOnly}
    />
  );
};

/**
 * Relevant suggestions returns the suggestions only if they are not null
 * and the given query matches the suggestionQuery
 */
export function relevantSuggestions(
  query: string,
  suggestions?: Suggestion[] | null,
  suggestionQuery?: string | null,
): Suggestion[] {
  if (query !== suggestionQuery || suggestions == null) return [];
  return suggestions.filter((s) => s.label !== "");
}

export const SearchBar = memo(SearchBarComponent);

/**
 * stringIsKeyValue checks to see if the string has a key and field,
 * i.e. that it has a string value after the colon.
 *
 * @param str the value to check
 */
function stringIsKeyValue(str: string): boolean {
  const [, value] = str.split(":");
  return !!value;
}

function isValidLabel(str: string): boolean {
  const [key, value] = str.split(":");
  if (key === "" || value === "") {
    return false;
  }

  if (
    validateStringAsBPLabelKey(key) !== undefined ||
    validateStringAsBPLabelKey(value) !== undefined
  ) {
    return false;
  }

  if (key === "configuration") {
    return false;
  }

  return true;
}

const EnterChip: React.FC = () => {
  return (
    <Box
      fontFamily="monospace"
      fontSize={10}
      color={colors.pixelPointBlue}
      border="1px solid"
      borderColor={colors.pixelPointBlue}
      borderRadius={"8px"}
      padding={"2px 4px"}
      marginRight={"4px"}
      marginY="auto"
      display="flex"
      alignItems={"center"}
    >
      add
      <ReturnIcon width="16px" height="16px" style={{ marginLeft: 4 }} />
    </Box>
  );
};
