import { Grid2 as Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  AdditionalInfo,
  GetResourceTypeQuery,
  Parameter,
  ParameterDefinition,
  ResourceConfiguration,
  ResourceType,
} from "../../graphql/generated";
import { ResourceDisplayNameInput } from "../ResourceConfigForm/ParameterInput/ResourceDisplayNameInput";
import { ParameterSection } from "../ResourceConfigForm/ParameterSection";
import { useResourceFormValues } from "../ResourceConfigForm/ResourceFormContext";
import { groupParameters } from "../ResourceConfigForm/utils";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import { ViewHeading } from "./ViewHeading";
import mixins from "../../styles/mixins.module.scss";

interface Props {
  title?: string;
  description?: string;
  additionalInfo?: AdditionalInfo | null;
  processors?: ResourceType[];
  parameterDefinitions: ParameterDefinition[];
  parameters?: Parameter[];
  stability?: string;
  resourceDocLink?: string;
  resourceType: NonNullable<GetResourceTypeQuery["resourceType"]>;
  items?: ResourceConfiguration[];
  onItemsChange?: (newItems: ResourceConfiguration[]) => void;
}

export const ResourceForm: React.FC<Props> = ({
  title,
  description,
  additionalInfo,
  parameterDefinitions,
  stability,
  resourceDocLink,
  resourceType,
  items,
  onItemsChange,
}) => {
  const { formValues, setFormValues } = useResourceFormValues();
  const { processorIndex = 0 } = useSnapshot();
  const groups = useMemo(
    () => groupParameters(parameterDefinitions),
    [parameterDefinitions],
  );

  const [newContext, setNewContext] = useState<string | undefined>();

  useEffect(() => {
    if (resourceType?.metadata?.name === "processor_bundle") {
      setNewContext("bundle");
    } else {
      setNewContext("default");
    }
  }, [resourceType]);

  // Use div if in bundle context, otherwise use form
  const Container = newContext !== "bundle" ? "form" : "div";

  return (
    <>
      {(processorIndex <= 1 || newContext !== "bundle") && (
        <Grid container spacing={3} className={mixins["mb-3"]}>
          <Grid size={{ xs: 12 }}>
            <ViewHeading
              heading={title}
              subHeading={description}
              additionalInfo={additionalInfo}
              stability={stability}
              resourceDocLink={resourceDocLink}
            />
          </Grid>
        </Grid>
      )}
      <div>
        <Container>
          <Grid container spacing={3} className={mixins["mb-5"]}>
            {(processorIndex <= 1 || newContext !== "bundle") && (
              <Grid size={{ xs: 7 }}>
                <ResourceDisplayNameInput
                  value={formValues.displayName}
                  onValueChange={(v: string) =>
                    setFormValues((prev) => ({ ...prev, displayName: v }))
                  }
                />
              </Grid>
            )}
            {groups.length === 0 ? (
              <Grid>
                <Typography>No additional configuration needed.</Typography>
              </Grid>
            ) : (
              groups.map((g, ix) => (
                <ParameterSection
                  key={`param-group-${ix}`}
                  group={g}
                  readOnly={false}
                  items={items}
                  onItemsChange={onItemsChange}
                />
              ))
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};
