import { LoadingButton } from "@mui/lab";
import { Box, Fade, Stack, TextField } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Card } from "../../../components/Auth0Imitation/Auth0Card";
import { Auth0CardBody } from "../../../components/Auth0Imitation/Auth0CardBody";
import { Auth0CardHeader } from "../../../components/Auth0Imitation/Auth0CardHeader";
import { EULAField } from "../../../components/EULAField";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import { useBranding } from "../../../hooks/useBranding";
import { createNewOrganization } from "../../../utils/rest/new-organization";
import { validateOrganizationName } from "../../../utils/rest/verify-organization";

export const SetupOrganizationPage: React.FC = withRequireLogin(() => {
  const [valid, setValid] = useState(false);
  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [orgFieldValue, setOrgFieldValue] = useState<string>("");
  const [orgFieldError, setOrgFieldError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  let errorMessage: string | null = null;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setValid(!orgFieldError && eulaAccepted);
  }, [eulaAccepted, orgFieldError]);

  const validateOrgName = useCallback(
    async (orgName: string) => {
      const error = await validateOrganizationName(orgName);
      setOrgFieldError(error);
    },
    [setOrgFieldError],
  );

  const debouncedValidate = useMemo(
    () => debounce(validateOrgName, 200),
    [validateOrgName],
  );

  async function onOrgFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOrgFieldValue(e.target.value);
    debouncedValidate(e.target.value);
  }

  async function handleGetStarted() {
    const orgFieldError = await validateOrganizationName(orgFieldValue);
    if (orgFieldError) {
      setOrgFieldError(orgFieldError);
      return;
    }
    setLoading(true);
    try {
      const resp = await createNewOrganization({
        organizationName: orgFieldValue,
        accountName: "My First Project",
        eulaAccepted: eulaAccepted,
      });
      if (!resp.ok) {
        if (resp.status === 400) {
          errorMessage =
            "Organization name can only contain letters, numbers, dashes and spaces.";
        } else {
          errorMessage = `Failed to create organization, got status ${resp.status}`;
        }
        throw new Error(errorMessage);
      }
      navigate("/agents");
    } catch (e) {
      console.error(errorMessage, {
        error: e,
        organizationName: orgFieldValue,
      });
      enqueueSnackbar((e as Error).message, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleEnter(e: React.KeyboardEvent) {
    if (e.key === "Enter" && valid) {
      handleGetStarted();
    }
  }

  const brandName = useBranding();
  const header = `Create your Organization in ${brandName.longName}`;

  return (
    <Auth0Card>
      <Auth0CardHeader description={header} />
      <Auth0CardBody>
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          onKeyDown={handleEnter}
          marginBottom="32px"
          width="100%"
        >
          <TextField
            value={orgFieldValue}
            onChange={onOrgFieldChange}
            fullWidth
            error={Boolean(orgFieldError)}
            helperText={orgFieldError ?? " "}
            label="Organization Name"
            placeholder="My Organization*"
          />
          <EULAField
            onChange={(e) => setEulaAccepted(e.target.checked)}
            checked={eulaAccepted}
          />
        </Box>
        <Stack width="100%" height="40px" alignItems="center">
          <Fade in={valid}>
            <LoadingButton
              loading={loading}
              onClick={handleGetStarted}
              endIcon={<ArrowRightIcon />}
            >
              Get started
            </LoadingButton>
          </Fade>
        </Stack>
      </Auth0CardBody>
    </Auth0Card>
  );
});
