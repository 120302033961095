import { Alert, Button, Typography } from "@mui/material";
import React from "react";
import styles from "./login-error.module.scss";

interface LoginErrorProps {
  error: string;
  onClick: () => void;
  buttonText?: string;
}

export const LoginError: React.FC<LoginErrorProps> = ({
  error,
  onClick,
  buttonText = "Return to Login",
}) => {
  return (
    <Alert severity="error" className={styles["alert-card"]} icon={false}>
      <Typography variant="h6" fontWeight="bold" color="error">
        Login Error
      </Typography>
      <Typography variant="body1" color="inherit" mb={2} maxWidth={300}>
        {error}
      </Typography>
      <Button onClick={onClick} variant="contained">
        {buttonText}
      </Button>
    </Alert>
  );
};
