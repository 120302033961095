import { ApolloError } from "@apollo/client";
import { isEqual, isFunction } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import {
  GetResourceTypesQuery,
  Kind,
  PipelineType,
  ResourceConfiguration,
  useGetResourceTypeLazyQuery,
} from "../../graphql/generated";
import { UpdateStatus } from "../../types/resources";
import { BPResourceConfiguration } from "../../utils/classes";
import { BPExtension } from "../../utils/classes/extension";
import { BPProcessor } from "../../utils/classes/processor";
import { applyResources } from "../../utils/rest/apply-resources";
import { trimVersion } from "../../utils/version-helpers";
import { ReusableProcessors } from "../ProcessorsDialog/withCommonProcessorDialog";
import { FormValues } from "../ResourceConfigForm";
import { DialogResource } from "../ResourceDialog";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import { AllItemsView } from "./AllItemsView";
import { ChooseView } from "./ChooseView";
import { CreateConfigureView } from "./CreateConfigureView";
import { EditResourceView } from "./EditResourceView";
import { SelectView } from "./SelectView";

enum Page {
  MAIN,
  CREATE_RESOURCE_SELECT,
  CREATE_RESOURCE_CONFIGURE,
  EDIT_RESOURCE,
  CHOOSE_VIEW,
  PREVIEW_RECOMMENDATION_VIEW,
}

interface ResourceConfigurationEditorProps {
  // The initial list of processors or extensions to edit
  initItems: ResourceConfiguration[];
  // The stateful value of the current resource configurations
  items: ResourceConfiguration[];
  // Called to change the current state of resourceConfigurations
  onItemsChange: (newItems: ResourceConfiguration[]) => void;
  // called to change current state of resourceConfigurations on edit
  onEditItemsChange: (newItems: ResourceConfiguration[]) => void;

  readOnly?: boolean;

  // The types of telemetry that are available for the pipeline, used to determine
  // which resource types can be used.
  telemetryTypes: PipelineType[];

  kind: Kind.Processor | Kind.Extension;

  reusableResources?: ReusableProcessors; // or extensions

  fetchReusableResourcesError?: ApolloError;

  refetchReusableResources?: () => void;

  refetchConfiguration: () => void;

  closeDialog: () => void;

  // updateInlineItems will be called when the user saves the inline items.
  // For processors it should handle saving the inline processors on that
  // item, and for extensions it should handle saving the inline extensions
  // on the configuration.  It should return true if saving was successful
  // and false if saving failed. There is no handling in the component, it is
  // up to the provider of the function to handle the saving and error.
  updateInlineItems: (items: ResourceConfiguration[]) => Promise<boolean>;

  // onDelete is an optional prop that, if set, will show a Delete button
  // on the AllItemsView. If the button is clicked, onDelete will be called.
  onDelete?: () => void;
  // breadcrumbPage is the current view of the resource configuration editor
  breadcrumbPage?: Page;
  //setBreadcrumbPage sets the current page of the resource configuration editor
  setBreadcrumbPage?: (page: Page) => void;
  // setBreadcrumbProcessor sets the processor to display in the breadcrumb
  setBreadcrumbProcessor?: (processor: string) => void;
  //setOnReturnToAll sets the function to call when the user returns to the main view
  setOnReturnToAll?: (callback: () => void) => void;
  //setOnReturnToSelectView sets the function to call when the user returns to the select view
  setOnReturnToSelectView?: (callback: () => void) => void;
  // context is the context of the resource configuration editor
  context?: string;
  // setContext sets the context of the resource configuration editor
  setContext?: (context: string) => void;
}

export type ResourceType = GetResourceTypesQuery["resourceTypes"][0];

export const ResourceConfigurationEditor: React.FC<
  ResourceConfigurationEditorProps
> = ({
  closeDialog,
  fetchReusableResourcesError,
  initItems,
  items,
  kind,
  onDelete,
  breadcrumbPage,
  setBreadcrumbPage,
  onItemsChange,
  onEditItemsChange,
  readOnly,
  refetchConfiguration,
  refetchReusableResources,
  reusableResources,
  telemetryTypes,
  updateInlineItems,
  setBreadcrumbProcessor,
  setOnReturnToAll,
  setOnReturnToSelectView,
  context,
  setContext,
}) => {
  const [view, setView] = useState(Page.MAIN);
  const [newResourceType, setNewResourceType] = useState<ResourceType | null>(
    null,
  );
  const [editingItemIndex, setEditingItemIndex] = useState<number>(-1);
  const {
    processorIndex,
    setProcessorIndex,
    applyQueue,
    setApplyQueue,
    bundleProcessors,
    setBundleProcessors,
  } = useSnapshot();

  const [previewRecommendation, setPreviewRecommendation] =
    useState<ResourceConfiguration | null>(null);

  const [matchingResources, setMatchingResources] =
    useState<ReusableProcessors>();

  const { enqueueSnackbar } = useSnackbar();

  const [originalItems, setOriginalItems] =
    useState<ResourceConfiguration[]>(items);

  const [getResourceType] = useGetResourceTypeLazyQuery({
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Error retrieving resource type", {
        variant: "error",
      });
    },
  });

  /* -------------------------------- Functions ------------------------------- */

  const handleReturnToAll = useCallback(
    (operation?: string) => {
      if (operation !== "delete") {
        setProcessorIndex((processorIndex ?? 0) - 1);
      }
      if (!context) {
        setProcessorIndex(0);
      }
      setView(Page.MAIN);
      setBreadcrumbPage?.(Page.MAIN);
      setNewResourceType(null);
      setPreviewRecommendation(null);
      setBreadcrumbProcessor && setBreadcrumbProcessor("");
    },
    [
      context,
      processorIndex,
      setBreadcrumbPage,
      setBreadcrumbProcessor,
      setProcessorIndex,
    ],
  );

  const filterProcessors = useCallback(() => {
    let newItems = [...(originalItems || [])];

    onItemsChange(newItems);
  }, [originalItems, onItemsChange]);

  const filterAndReturnToAll = useCallback(() => {
    filterProcessors();
    handleReturnToAll();
  }, [filterProcessors, handleReturnToAll]);

  const handleReturnToSelectView = useCallback(() => {
    filterProcessors();
    setView(Page.CREATE_RESOURCE_SELECT);
    setBreadcrumbPage?.(Page.CREATE_RESOURCE_SELECT);
    setNewResourceType(null);
    isFunction(refetchReusableResources)
      ? refetchReusableResources()
      : kind !== Kind.Extension &&
        console.error("No function provided for refetchReusableResources");
    setBreadcrumbProcessor && setBreadcrumbProcessor("");
  }, [
    filterProcessors,
    setBreadcrumbPage,
    refetchReusableResources,
    kind,
    setBreadcrumbProcessor,
  ]);

  const breadcrumbHandleReturnToSelectView = useCallback(() => {
    handleReturnToSelectView();
    setProcessorIndex(1);
  }, [handleReturnToSelectView, setProcessorIndex]);

  // handleSelectNewProcessorType is called when a user selects a processor type
  // in the CreateProcessorSelect view.
  function handleSelectNewResourceType(type: ResourceType) {
    setOnReturnToSelectView?.(() => breadcrumbHandleReturnToSelectView);
    setBreadcrumbProcessor &&
      type.metadata.displayName &&
      setBreadcrumbProcessor(type.metadata.displayName);
    if (type.metadata.name === "processor_bundle") {
      setContext?.("bundle");

      if ((processorIndex ?? 0) > 1 && context === "bundle") {
        console.error(
          "Error: Cannot add a processor bundle to another processor bundle",
        );
        return;
      }
      setProcessorIndex(1);
    }
    if (fetchReusableResourcesError) {
      console.error(fetchReusableResourcesError);
      enqueueSnackbar("Error retrieving reusable resources", {
        variant: "error",
      });
      isFunction(refetchReusableResources)
        ? refetchReusableResources()
        : console.error("No function provided for refetchReusableResources");
    }
    const matchingResources = reusableResources?.filter((p) => {
      return (
        trimVersion(p.spec.type) === type.metadata.name &&
        type.metadata.displayName !== "Count Telemetry" &&
        type.metadata.displayName !== "Extract Metric" &&
        type.metadata.displayName !== "Count Logs"
      );
    });
    setNewResourceType(type);
    if (matchingResources && matchingResources?.length > 0) {
      setMatchingResources(matchingResources);
      setView(Page.CHOOSE_VIEW);
      setBreadcrumbPage?.(Page.CREATE_RESOURCE_CONFIGURE);
    } else {
      setView(Page.CREATE_RESOURCE_CONFIGURE);
      setBreadcrumbPage?.(Page.CREATE_RESOURCE_CONFIGURE);
    }
  }

  function handleCreateNewResource() {
    setView(Page.CREATE_RESOURCE_CONFIGURE);
  }

  function handleEnterSelectView() {
    setProcessorIndex((processorIndex ?? 0) + 1);
    setView(Page.CREATE_RESOURCE_SELECT);
    setBreadcrumbPage?.(Page.CREATE_RESOURCE_SELECT);
    setOnReturnToAll?.(() => filterAndReturnToAll);
    isFunction(refetchReusableResources)
      ? refetchReusableResources()
      : kind !== Kind.Extension &&
        console.error("No function provided for refetchReusableResources");
  }
  const { refetchResourceRecommendations, onAcceptResourceRecommendation } =
    useSnapshot();

  // handleAddItemFromRecommendation adds a new processor to the list of processors
  // and sets the recommendation field to the accepted recommendation
  async function handleAddItemFromRecommendation(formValues: FormValues) {
    if (!previewRecommendation) return;
    formValues.recommendation = previewRecommendation!.recommendation ?? "";
    onAcceptResourceRecommendation(previewRecommendation.id!);
    handleAddItem(formValues);
  }

  const areProcessorsEqual = (
    a: ResourceConfiguration,
    b: ResourceConfiguration,
  ) => isEqual(a, b);

  async function handleAddItem(formValues: FormValues) {
    const itemConfig = new BPResourceConfiguration();
    let newItems = [...items];

    // Check if adding a processor bundle
    if (formValues.processors) {
      const formValuesProcessorSet = new Set(
        formValues.processors.map((processor) => JSON.stringify(processor)),
      );

      // Filter out extra processors added when inside bundle context
      newItems = newItems.filter((item) => {
        const isInFormValues = formValuesProcessorSet.has(JSON.stringify(item));
        const originalBundleProcessors = bundleProcessors?.some(
          (bundleProcessor) => areProcessorsEqual(bundleProcessor, item),
        );

        return (
          !(formValues.processors ?? []).some((processor) =>
            areProcessorsEqual(processor, item),
          ) &&
          (!originalBundleProcessors || isInFormValues)
        );
      });
    }

    // Set parameters and processors for the new item
    itemConfig.setParamsFromMap(formValues);
    itemConfig.setProcessors(formValues.processors ?? []);
    itemConfig.type = newResourceType!.metadata.name;

    // Add the item to the bundle if in context "bundle"
    if (context === "bundle") {
      setBundleProcessors([
        ...(bundleProcessors ?? []),
        itemConfig as ResourceConfiguration,
      ]);
    } else {
      setOriginalItems([
        ...(originalItems ?? []),
        itemConfig as ResourceConfiguration,
      ]);
    }

    // Add the new item to the list of items
    newItems = [...newItems, itemConfig];

    onItemsChange(newItems);
    handleReturnToAll();
  }

  // handleSaveExistingInlineResource saves changes to an existing resourceConfiguration in the list
  function handleSaveExistingInlineResource(formValues: FormValues) {
    const itemConfig = new BPResourceConfiguration(items[editingItemIndex]);
    itemConfig.setParamsFromMap(formValues);
    itemConfig.setProcessors(formValues.processors ?? []);

    let newItems = [...items];
    newItems[editingItemIndex] = itemConfig;
    onEditItemsChange(newItems);

    handleReturnToAll();
  }
  // handleSaveExistingPersistentResource adds a processor to the apply queue
  function handleSaveExistingPersistentResource(
    resource: BPProcessor | BPExtension,
  ) {
    const foundIndex = applyQueue.findIndex(
      (p) => p.name() === resource.name(),
    );
    if (foundIndex !== -1) {
      const newApplyQueue = [...applyQueue];
      newApplyQueue[foundIndex] = resource;
      setApplyQueue(newApplyQueue);
    } else {
      setApplyQueue([...applyQueue, resource]);
    }
    onItemsChange(items);

    handleReturnToAll();
  }

  function handleSaveReusableResourceChoice(resource: DialogResource) {
    setProcessorIndex((processorIndex ?? 0) - 1);
    const processorConfig = new BPResourceConfiguration();
    processorConfig.name = resource.metadata.name;

    const processors = [...items, processorConfig];
    onItemsChange(processors);

    if (context === "bundle") {
      setBundleProcessors([...(bundleProcessors ?? []), processorConfig]);
    } else {
      setOriginalItems([...(originalItems ?? []), processorConfig]);
    }

    handleReturnToAll();
  }

  async function onAddToLibrary(values: { [key: string]: any }, name: string) {
    const reusableProcessor = new BPProcessor({
      metadata: {
        name: name,
        id: "",
        version: 0,
        displayName: values.displayName,
      },
      spec: {
        parameters: [],
        type: items[editingItemIndex].type!,
        disabled: items[editingItemIndex].disabled,
      },
    });

    reusableProcessor.setParamsFromMap(values);
    reusableProcessor.setProcessors(values.processors ?? []);

    try {
      await applyResources([reusableProcessor]);
      enqueueSnackbar(`Successfully added ${kind} to Library!`, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(`Failed to add ${kind} to Library.`, {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      return;
    }

    const updatedProcessor = new BPResourceConfiguration({
      name: reusableProcessor.metadata.name,
      disabled: reusableProcessor.spec.disabled,
      processors: reusableProcessor.spec.processors,
    });

    // find the old inline item and replace it with this one
    const newItems = items.map((i, idx) => {
      if (idx === editingItemIndex) {
        return updatedProcessor;
      }
      return i;
    });

    onItemsChange(newItems);

    updateInlineItems(newItems);

    isFunction(refetchReusableResources)
      ? refetchReusableResources()
      : console.error("No function provided for refetchReusableResources");
  }

  /**
   * Copy the values from the library resource to the inline resource, and replace the library resource
   *
   * @param values Library resource parameters
   * @param name Library resource name
   */
  async function onUnlinkFromLibrary(
    values: { [key: string]: any },
    name: string,
    type: string,
    unlinkDisplayName: string,
  ) {
    const itemName = name ?? items[editingItemIndex].name;
    const itemConfig = new BPResourceConfiguration(items[editingItemIndex]);
    itemConfig.setParamsFromMap(values);
    itemConfig.setProcessors(values.processors ?? []);
    itemConfig.name = undefined;
    itemConfig.id = undefined;
    itemConfig.type = type;
    itemConfig.displayName = unlinkDisplayName
      ? unlinkDisplayName
      : itemName
        ? trimVersion(itemName)
        : "";

    const newItems = [...items];
    newItems[editingItemIndex] = itemConfig;

    enqueueSnackbar(
      `Successfully unlinked ${trimVersion(itemName)} from Library!`,
      {
        variant: "success",
        autoHideDuration: 3000,
      },
    );
    onItemsChange(newItems);
    handleReturnToAll();
  }

  // handleRemoveItem removes a processor from the list of processors
  async function handleRemoveItem(index: number) {
    const newItems = [...items];
    newItems.splice(index, 1);
    onEditItemsChange(newItems);
    if (context !== "bundle") {
      const newItems = [...originalItems];
      newItems.splice(index, 1);
      setOriginalItems(newItems);
    }
    handleReturnToAll("delete");
  }

  // handleEditItemClick sets the editing index and switches to the edit page
  function handleEditItemClick(index: number) {
    setOnReturnToAll?.(() => filterAndReturnToAll);
    setProcessorIndex((processorIndex ?? 0) + 1);
    setEditingItemIndex(index);
    setView(Page.EDIT_RESOURCE);
    setBreadcrumbPage?.(Page.EDIT_RESOURCE);
  }
  // handleSave saves the processors to the backend and closes the dialog.
  async function handleSave() {
    setProcessorIndex(0);
    const inlineChange = !isEqual(initItems, items);
    const resourceChange = applyQueue.length > 0;
    var shouldCloseDialog = true;

    if (!inlineChange && !resourceChange) {
      closeDialog();
    }

    if (resourceChange) {
      const { updates } = await applyResources(applyQueue);
      if (updates.some((u) => u.status === UpdateStatus.INVALID)) {
        enqueueSnackbar("Failed to save resources", {
          variant: "error",
          key: "save-resources-error",
        });
        shouldCloseDialog = false;
      }
    }

    if (inlineChange) {
      shouldCloseDialog = await updateInlineItems(items);
    }

    if (shouldCloseDialog) {
      refetchConfiguration();
      if (isFunction(refetchResourceRecommendations)) {
        refetchResourceRecommendations();
      }
      closeDialog();
      enqueueSnackbar(`Saved ${kind}s!`, { variant: "success" });
    }
  }

  async function handleViewRecommendation(rec: ResourceConfiguration) {
    const { data } = await getResourceType({
      variables: { kind: Kind.ProcessorType, name: rec.type! },
    });

    if (data == null || data.resourceType == null) {
      console.error("Failed to get resource type for recommendation");
      return;
    }

    setNewResourceType(data.resourceType);
    setPreviewRecommendation(rec);
    setBreadcrumbProcessor?.(data.resourceType.metadata.displayName ?? "");
    setOnReturnToAll?.(() => filterAndReturnToAll);
    setBreadcrumbPage?.(Page.PREVIEW_RECOMMENDATION_VIEW);
    setProcessorIndex((processorIndex ?? 0) + 1);
    setView(Page.PREVIEW_RECOMMENDATION_VIEW);
  }

  let current: JSX.Element;
  switch (view) {
    case Page.MAIN:
      current = (
        <AllItemsView
          items={items}
          originalItems={originalItems}
          setOriginalItems={setOriginalItems}
          onAddItem={handleEnterSelectView}
          onDelete={onDelete}
          onDeleteItem={handleRemoveItem}
          onEditItem={handleEditItemClick}
          onItemsChange={onItemsChange}
          onSave={handleSave}
          onViewRecommendation={handleViewRecommendation}
          readOnly={Boolean(readOnly)}
          resourceKind={kind}
          context={context}
          libraryResources={reusableResources}
        />
      );
      break;
    case Page.CREATE_RESOURCE_SELECT:
      current = (
        <SelectView
          resourceKind={kind}
          telemetryTypes={telemetryTypes}
          onBack={() => {
            setView(Page.MAIN);
            setBreadcrumbPage?.(Page.MAIN);
            setProcessorIndex((processorIndex ?? 0) - 1);
          }}
          onSelect={handleSelectNewResourceType}
          context={context}
        />
      );
      break;
    case Page.CREATE_RESOURCE_CONFIGURE:
      current = (
        <CreateConfigureView
          resourceKind={kind}
          resourceType={newResourceType!}
          onBack={handleReturnToSelectView}
          onSave={(formValues) => {
            handleAddItem(formValues);
          }}
          items={items}
          onItemsChange={onItemsChange}
          onClose={() => {
            closeDialog();
            setProcessorIndex((processorIndex ?? 0) - 1);
          }}
        />
      );
      break;
    case Page.EDIT_RESOURCE:
      current = (
        <EditResourceView
          resourceKind={kind}
          items={items}
          editingIndex={editingItemIndex}
          applyQueue={applyQueue}
          onEditInlineSave={handleSaveExistingInlineResource}
          onEditPersistentResourceSave={handleSaveExistingPersistentResource}
          onBack={handleReturnToAll}
          onRemove={handleRemoveItem}
          readOnly={readOnly}
          libraryResources={reusableResources}
          onAddToLibrary={onAddToLibrary}
          onUnlinkFromLibrary={onUnlinkFromLibrary}
          setBreadcrumbProcessor={setBreadcrumbProcessor}
          context={context}
        />
      );
      break;
    case Page.CHOOSE_VIEW:
      current = (
        <ChooseView
          resourceKind={kind}
          onBack={handleReturnToSelectView}
          onCreate={handleCreateNewResource}
          reusableResources={matchingResources!}
          selected={newResourceType!}
          handleSaveExisting={handleSaveReusableResourceChoice}
        />
      );
      break;
    case Page.PREVIEW_RECOMMENDATION_VIEW:
      const initValues: FormValues = {
        displayName: previewRecommendation!.displayName!,
      };

      for (const p of previewRecommendation!.parameters!) {
        initValues[p.name] = p.value;
      }
      return (
        <CreateConfigureView
          resourceKind={kind}
          resourceType={newResourceType!}
          onBack={filterAndReturnToAll}
          onSave={handleAddItemFromRecommendation}
          onClose={closeDialog}
          actionButtonText="Accept"
          initValues={initValues}
        />
      );
  }

  return current;
};
