import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Card } from "../../components/Auth0Imitation/Auth0Card";
import { Auth0CardHeader } from "../../components/Auth0Imitation/Auth0CardHeader";
import { CodeBlock } from "../../components/CodeBlock";
import { HelpCircleIcon } from "../../components/Icons";
import { OrganizationSwitcher } from "../../components/OrganizationSwitcher/OrganizationSwitcher";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { useBranding } from "../../hooks/useBranding";
import mixins from "../../styles/mixins.module.scss";
import styles from "./license-required.module.scss";

interface LicenseErrorResponse {
  message?: string;
  errorCode?: number;
}

enum LicenseErrorCodes {
  Unknown,
  NoLicense,
  Expired,
  Unverified,
}

const LicenseRequiredPageContent: React.FC = () => {
  const [licenseErrorCode, setLicenseErrorCode] = useState<number | null>(null);
  const [expanded, setExpanded] = useState<"q1" | "q2" | false>("q1");

  const branding = useBranding();
  const navigate = useNavigate();

  useEffect(() => {
    // This is a bit hacky - but lets make a request to the server
    // and check the error code to get a more specific error message.
    async function getLicenseErrorCode() {
      if (!document.hasFocus()) {
        return;
      }
      try {
      } catch (_e) {
        setLicenseErrorCode(LicenseErrorCodes.Unknown);
      }
      const response = await fetch("/v1/version", {});
      if (response.status !== 452) {
        navigate("/agents");
        return;
      }

      const errorResponse = (await response.json()) as LicenseErrorResponse;
      if (errorResponse.errorCode) {
        setLicenseErrorCode(errorResponse.errorCode);
      } else {
        setLicenseErrorCode(LicenseErrorCodes.Unknown);
      }
    }

    getLicenseErrorCode();
    // poll every 3 seconds
    const cancel = setInterval(getLicenseErrorCode, 3000);

    return () => clearTimeout(cancel);
  }, [navigate]);

  const title = useMemo(() => {
    switch (licenseErrorCode) {
      case LicenseErrorCodes.Expired:
        return "License Expired";
      case LicenseErrorCodes.Unverified:
        return "License Unverified";
      default:
        return "License Required";
    }
  }, [licenseErrorCode]);

  if (licenseErrorCode === null) {
    return (
      <Stack
        height="100vh"
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={40} />
      </Stack>
    );
  }

  function handleExpandChange(panel: "q1" | "q2") {
    setExpanded(expanded === panel ? false : panel);
  }

  return (
    <Auth0Card>
      <Auth0CardHeader title={title} />

      <OrganizationSwitcher sx={{ px: 10, pb: 6 }} />

      <CardContent>
        <Typography variant="h6" fontWeight={600} marginBottom={2}>
          Frequently Asked Questions
        </Typography>
        <Accordion
          expanded={expanded === "q1"}
          onChange={() => handleExpandChange("q1")}
        >
          <AccordionSummary>
            <Stack direction="row" alignItems="center" spacing={2}>
              <HelpCircleIcon width="15px" />

              <Typography className={styles.accordionTitle}>
                How do I obtain a license?
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can <a href={branding.supportEmail}>contact support</a> to
              request a new license.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "q2"}
          onChange={() => handleExpandChange("q2")}
        >
          <AccordionSummary>
            <Stack direction="row" alignItems="center" spacing={2}>
              <HelpCircleIcon width="15px" />

              <Typography>How do I set my new license key?</Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack spacing={3}>
              <Typography>
                Depending on your deployment there could be a few ways to set
                your license.
              </Typography>

              <div>
                <Typography fontWeight={600}>
                  {branding.longName} is running as a single instance either on
                  a virtual machine or on-premise.
                </Typography>
                <Box paddingLeft={2} marginTop={2}>
                  <Typography marginBottom={2}>
                    You can set your license key using the{" "}
                    <code className={styles.code}>bindplane init license</code>{" "}
                    command. A typical command would look like this:
                  </Typography>
                  <CodeBlock value={initLicenseCommand} />
                </Box>
              </div>

              <div>
                <Typography fontWeight={600}>
                  {branding.longName} is running in a Kubernetes cluster.
                </Typography>
                <Box paddingLeft={2} marginTop={2}>
                  <Typography marginBottom={2}>
                    You must update the{" "}
                    <code className={styles.code}>license</code> secret value in
                    your Helm chart. See{" "}
                    <a
                      href="https://github.com/observIQ/bindplane-op-helm?tab=readme-ov-file#secrets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      this README
                    </a>{" "}
                    for details.
                  </Typography>
                </Box>
              </div>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </CardContent>
      <CardActions className={styles.actions}>
        <Button
          href={branding.supportEmail}
          variant="outlined"
          color="secondary"
          className={mixins["mr-2"]}
        >
          Contact Support
        </Button>
      </CardActions>
    </Auth0Card>
  );
};

const initLicenseCommand = `sudo BINDPLANE_CONFIG_HOME=/var/lib/bindplane \\
/usr/local/bin/bindplane init license \\
--config /etc/bindplane/config.yaml`;

export const LicenseRequiredPage = withRequireLogin(LicenseRequiredPageContent);
