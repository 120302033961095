import { FormControl, Select, Stack, Typography } from "@mui/material";
import { MenuItem } from "@mui/material";
import { SxProps, Theme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { changeAccount } from "../../utils/rest/change-account";
import { CheckIcon } from "../Icons";
import styles from "./organization-switcher.module.scss";

interface AllOrganizationsAccountsResponse {
  organizationAccounts: {
    organization: {
      metadata: {
        id: string;
        name: string;
      };
    };
    accounts: {
      metadata: {
        id: string;
        name: string;
      };
    }[];
  }[];
  activeAccountId: string;
}

interface OrganizationSwitcherProps {
  sx?: SxProps<Theme>;
}

// This is the organization switcher component used on the License Required Page, the
// org switcher in the EESettingsMenu is separate and does not use this component.
export const OrganizationSwitcher: React.FC<OrganizationSwitcherProps> = ({
  sx,
}) => {
  const [data, setData] = useState<AllOrganizationsAccountsResponse | null>(
    null,
  );

  const fetchAllOrganizationsAccounts = async () => {
    try {
      const response = await fetch("/v1/organizations/accounts/all");
      if (!response.ok) {
        throw new Error("Failed to fetch organizations and projects");
      }
      const data = await response.json();
      setData(data);
    } catch (err) {
      console.error("Failed to fetch organizations and projects", {
        error: err,
      });
      enqueueSnackbar("Failed to fetch organizations and projects", {
        variant: "error",
        key: "fetch-organizations-and-projects-error",
      });
    }
  };

  useEffect(() => {
    fetchAllOrganizationsAccounts();
  }, []);

  // don't render if the user is only in 1 org
  if (
    !data ||
    data?.activeAccountId === "" ||
    (data?.organizationAccounts?.length ?? 0) < 2
  ) {
    return null;
  }

  const curOrg = data?.organizationAccounts?.find((org) =>
    org.accounts.find((a) => a.metadata.id === data?.activeAccountId),
  )?.organization;
  const otherOrgs = data?.organizationAccounts?.filter(
    (org) =>
      org.organization.metadata.id !== curOrg?.metadata.id &&
      org.accounts.length > 0,
  );

  if (!curOrg || !otherOrgs) {
    return null;
  }

  return (
    <Stack alignItems="center" spacing={2} sx={sx}>
      <Typography>Switch Organization</Typography>
      <FormControl fullWidth sx={{ paddingX: "12px" }}>
        <Select
          MenuProps={{
            sx: {
              "&& .MuiMenuItem-root": {
                backgroundColor: "white",
              },
              "&& .MuiMenuItem-root:hover": {
                backgroundColor: "rgba(0, 115, 229, 0.08)",
              },
            },
          }}
          id="organization-switcher-select"
          value={curOrg.metadata.id}
          size="small"
          variant="standard"
          renderValue={() => curOrg.metadata.name}
          defaultValue={curOrg.metadata.id}
          data-testid="organization-switcher-select"
        >
          <Stack maxHeight="300px" overflow="auto">
            {curOrg && (
              <MenuItem
                disabled
                selected
                classes={{ root: styles.current }}
                value={curOrg.metadata.id}
                data-testid={`current-org-${curOrg.metadata.id}`}
              >
                <Typography className={styles["current-typo"]}>
                  {curOrg.metadata.name}
                </Typography>
                <CheckIcon className={styles.check} />
              </MenuItem>
            )}
            {otherOrgs?.map((org) => (
              <MenuItem
                key={`switcher-${org.organization.metadata.id}`}
                onClick={() => changeAccount(org.accounts[0].metadata.id)}
                value={org.organization.metadata.id}
                data-testid={`other-org-${org.organization.metadata.id}`}
              >
                <Typography>{org.organization.metadata.name}</Typography>
              </MenuItem>
            ))}
          </Stack>
        </Select>
      </FormControl>
    </Stack>
  );
};
