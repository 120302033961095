import { gql } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { AgentTypeSelect } from "../../../../components/AgentTypeSelect";
import { ExternalLinkIcon } from "../../../../components/Icons";
import { PlatformSelect } from "../../../../components/PlatformSelect";
import { SafeLink } from "../../../../components/SafeLink";
import { useWizard } from "../../../../components/Wizard/WizardContext";
import {
  GetConfigurationNamesQuery,
  useGetConfigurationNamesQuery,
} from "../../../../graphql/generated";
import { ErrorResponse, InstallCommandResponse } from "../../../../types/rest";
import {
  SEARCH_PARAM_PLATFORM,
  SEARCH_PARAM_SECONDARY_PLATFORM,
} from "../../../configurations/new";
import { ConfigurationSelect } from "./ConfigurationSelect";
import {
  NoAgentVersionsError,
  NoV2ForSelectedPlatform,
} from "./FetchInstallCommandErrors";
import { AgentInstallFormValues } from "./InstallWizard";
import { useInstallWizard } from "./InstallWizardContext";
import {
  filterConfigurationsByPlatform,
  installCommandUrl,
  platformIsContainer,
} from "./utils";

gql`
  query GetConfigurationNames {
    configurations {
      configurations {
        metadata {
          id
          name
          version
          labels
        }
      }
    }
  }
`;

export const ChooseYourPlatform: React.FC = () => {
  const [allConfigs, setAllConfigs] =
    useState<GetConfigurationNamesQuery["configurations"]["configurations"]>();
  const [nextLoading, setNextLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { setValues, formValues, goToStep } =
    useWizard<AgentInstallFormValues>();
  const { installationId, setCommand, setAltCmd } = useInstallWizard();

  const {
    platform,
    selectedConfig,
    secondaryPlatform,
    secondaryPlatformRequired,
    agentType,
  } = formValues;

  useGetConfigurationNamesQuery({
    fetchPolicy: "network-only",
    pollInterval: 5000,
    onCompleted: (data) => {
      setAllConfigs(data.configurations.configurations);
    },
    onError: (error) => {
      console.error("Failed to load configurations", {
        error,
      });
      enqueueSnackbar("Failed to load configurations", { variant: "error" });
    },
  });

  function handleSelectPlatform(p: string) {
    setValues({ platform: p, secondaryPlatform: null, selectedConfig: null });
  }

  function handleSelectSecondaryPlatform(p: string) {
    setValues({ secondaryPlatform: p, selectedConfig: null });
  }

  function handleSelectAgentType(t: string) {
    setValues({ agentType: t });
  }

  async function handleNextClick() {
    setNextLoading(true);

    try {
      const installResponse = await fetchInstallText(
        platform,
        secondaryPlatform,
        selectedConfig,
        installationId,
        agentType,
      );
      setCommand(installResponse.command);
      setAltCmd(installResponse.alternateCommand);
      goToStep(1);
    } catch (err) {
      console.error("Failed to get install command", {
        error: err,
        platform,
        secondaryPlatform,
        selectedConfig,
      });
      if (err instanceof NoAgentVersionsError) {
        enqueueSnackbar("No available agent versions found", {
          variant: "error",
        });
        return;
      }

      if (err instanceof NoV2ForSelectedPlatform) {
        enqueueSnackbar(
          "V2 collector not currently available for selected platform",
          {
            variant: "error",
          },
        );
        return;
      }

      enqueueSnackbar("Failed to get install command", {
        variant: "error",
      });
    } finally {
      setNextLoading(false);
    }
  }
  // a subset of allConfigs filtered by platform, it will
  // be empty if secondary platform is required and not selected
  const filteredConfigs = useMemo(() => {
    if (secondaryPlatformRequired && secondaryPlatform == null) {
      return [];
    }

    if (allConfigs != null && platform != null) {
      const platformFilter = secondaryPlatform ?? platform;
      return filterConfigurationsByPlatform(allConfigs, platformFilter);
    }
    return [];
  }, [allConfigs, platform, secondaryPlatform, secondaryPlatformRequired]);

  if (allConfigs == null) {
    return null;
  }

  return (
    <Stack height="100%">
      <Stack flexGrow={1}>
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h6" fontWeight={600}>
            Lets get some Agents installed.
          </Typography>
          <Typography>
            Your first step is to choose the platform you're shipping telemetry
            from.
          </Typography>
          <Box width={450}>
            <PlatformSelect
              platformValue={platform}
              secondaryPlatformValue={secondaryPlatform}
              size="small"
              onPlatformSelected={handleSelectPlatform}
              onSecondaryPlatformSelected={handleSelectSecondaryPlatform}
              setSecondaryPlatformRequired={(p) =>
                setValues({ secondaryPlatformRequired: p })
              }
            />
          </Box>

          {platform && filteredConfigs.length > 0 && (
            <>
              {secondaryPlatformRequired ? (
                <>
                  <Typography>
                    For this installation you'll need to first specify which
                    Configuration you'd like your agents to use.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography>
                    Looks like you already have a Configuration set up for this
                    platform.
                  </Typography>
                  <Typography>
                    You can specify a Configuration for your new Agent now
                    during installation, or you can leave it blank and assign
                    one later.
                  </Typography>
                </>
              )}

              <Box width={450}>
                <ConfigurationSelect
                  platform={secondaryPlatform ?? platform}
                  configs={filteredConfigs.map((c) => c.metadata.name)}
                  selectedConfig={selectedConfig ?? ""}
                  setSelectedConfig={(v) => setValues({ selectedConfig: v })}
                />
              </Box>
            </>
          )}

          {platform &&
            secondaryPlatform &&
            secondaryPlatformRequired &&
            filteredConfigs.length === 0 && (
              <Stack spacing={2} alignItems="flex-start">
                <Typography>
                  You don't have any Configurations for this platform. You'll
                  need to create one before you can install an agent.
                </Typography>
                <Button
                  LinkComponent={SafeLink}
                  variant="contained"
                  href={builderPath(platform, secondaryPlatform)}
                  target="_blank"
                  rel="noreferrer"
                  endIcon={<ExternalLinkIcon width={18} />}
                >
                  Go to Configuration Builder
                </Button>
              </Stack>
            )}

          <Box width={450}>
            <AgentTypeSelect
              size="small"
              onAgentTypeSelected={handleSelectAgentType}
            ></AgentTypeSelect>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        {formIsReady(formValues) && (
          <LoadingButton
            loading={nextLoading}
            variant="contained"
            onClick={handleNextClick}
          >
            Next
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

/**
 * fetchInstallText fetches the install command text for the given platform and
 * configuration.  It throws an error for a non 200 response.
 *
 * @param platform Platform form value
 * @param secondaryPlatform Secondary platform form value
 * @param configuration optional configuration name
 * @returns
 * @throws {NoAgentVersionError} if no agent versions are found
 * @throws {Error} if the response is not ok and not a 404
 */
async function fetchInstallText(
  platform: string,
  secondaryPlatform: string | null,
  configuration: string | null,
  installId: string,
  agentType: string,
): Promise<InstallCommandResponse> {
  const url = installCommandUrl({
    platform: secondaryPlatform ?? platform,
    configuration,
    installId,
    agentType,
  });

  const response = await fetch(url);

  if (response.status === 404) {
    throw new NoAgentVersionsError();
  }

  if (response.status === 400) {
    const err = (await response.json()) as ErrorResponse;
    if (
      err.errors.includes("selected platform not compatible with v2 collectors")
    ) {
      throw new NoV2ForSelectedPlatform();
    }
  }

  if (!response.ok) {
    throw new Error(`got status ${response.status} from ${url}`);
  }

  return (await response.json()) as InstallCommandResponse;
}

// formIsReady returns true when the form is ready to move to the next step
function formIsReady(values: AgentInstallFormValues): boolean {
  if (isEmpty(values.platform)) {
    return false;
  }

  if (values.secondaryPlatformRequired && isEmpty(values.secondaryPlatform)) {
    return false;
  }

  if (
    platformIsContainer(values.secondaryPlatform ?? values.platform) &&
    isEmpty(values.selectedConfig)
  ) {
    return false;
  }

  return true;
}

function builderPath(platform: string, secondaryPlatform: string): string {
  const search = new URLSearchParams();
  search.set(SEARCH_PARAM_PLATFORM, platform);
  search.set(SEARCH_PARAM_SECONDARY_PLATFORM, secondaryPlatform);

  return `/configurations/new?${search.toString()}`;
}
