import { Autocomplete, TextField, Chip } from "@mui/material";
import { isFunction, isArray } from "lodash";
import { memo, useState } from "react";
import {
  DataPoint,
  Log,
  Span,
  useSnapshot,
} from "../../SnapShotConsole/SnapshotContext";
import { useValidationContext } from "../ValidationContext";
import { validateOTTLFields } from "../validation-functions";
import { ConditionMatch } from "./ConditionInput";
import { getFieldKeyOptions } from "./OTTLFieldInput";
import { ParamInputProps } from "./ParameterInput";
import { parameterHelperText } from "./utils";
import styles from "./parameter-input.module.scss";

interface OTTLFieldsInputProps extends ParamInputProps<string[]> {
  getOptions?: (
    match: ConditionMatch,
    logs: Log[],
    metrics: DataPoint[],
    traces: Span[],
    processedLogs: Log[],
    processedMetrics: DataPoint[],
    processedTraces: Span[],
  ) => string[];
}

const OTTLFieldsInputComponent: React.FC<OTTLFieldsInputProps> = ({
  getOptions = getFieldKeyOptions,
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const { errors, touched, touch, setError } = useValidationContext();
  const {
    logs,
    metrics,
    traces,
    processedLogs,
    processedMetrics,
    processedTraces,
  } = useSnapshot();
  const [inputValue, setInputValue] = useState("");

  // handleAutocompleteValueChange is called whenever a pill is created or deleted. Represents the string[] value of the input parameter.
  function handleAutocompleteValueChange(
    _e: React.SyntheticEvent,
    newValue: string[],
  ) {
    if (!touched[definition.name]) {
      touch(definition.name);
    }
    isFunction(onValueChange) && onValueChange(newValue || "");
    setError(
      definition.name,
      validateOTTLFields(newValue, definition.required),
    );
  }

  // Add chip click handler
  function handleChipClick(ix: number) {
    if (!isArray(value)) {
      return;
    }

    setInputValue(value[ix]);

    const copy = [...value];
    copy.splice(ix, 1);
    isFunction(onValueChange) && onValueChange(copy);
  }

  // Add blur handler
  function handleBlur() {
    touch(definition.name);
    if (inputValue.trim()) {
      const newValue = [...(value ?? []), inputValue.trim()];
      handleAutocompleteValueChange(null as any, newValue);
      setInputValue("");
    }
  }

  return (
    <Autocomplete
      sx={{ flexGrow: 1 }}
      freeSolo
      multiple
      disableCloseOnSelect
      options={getOptions(
        definition.options?.ottlContext as ConditionMatch,
        logs,
        metrics,
        traces,
        processedLogs,
        processedMetrics,
        processedTraces,
        false,
      )}
      slotProps={{
        popper: {
          placement: "bottom-start",
          style: {
            width: "auto",
            left: "0px",
          },
        },
      }}
      value={value}
      inputValue={inputValue}
      onInputChange={(_e, newValue) => setInputValue(newValue)}
      disabled={readOnly}
      onChange={handleAutocompleteValueChange}
      onBlur={handleBlur}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            size="small"
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            classes={{ root: styles.chip, label: styles["chip-label"] }}
            onClick={() => handleChipClick(index)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          slotProps={{
            input: params.InputProps,
            formHelperText: { sx: { paddingLeft: "-2px" } },
          }}
          value={value || ""}
          disabled={readOnly}
          name={definition.name}
          fullWidth
          size="small"
          label={definition.label}
          helperText={parameterHelperText(definition, errors, touched)}
          required={definition.required}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      )}
    />
  );
};

export const OTTLFieldsInput = memo(OTTLFieldsInputComponent);
