import { enqueueSnackbar } from "notistack";

/*
  changeAccount changes the active account for the user to the one specified.
  Returns a 204 if the account is switched successfully.
  Returns a 400 if the accountId is not provided correctly.
  Returns a 401 if the user is not authenticated.
  Returns a 403 if the user does not have access to the account or the account does not exist.
  Returns a 500 if there is an error switching accounts.
*/
export async function changeAccount(accountId: string) {
  try {
    await fetch("/v1/accounts/current", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accountId }),
    });
    window.location.href = "/overview";
  } catch (err) {
    console.error("Failed to change project", { error: err });
    enqueueSnackbar("Error changing project", {
      variant: "error",
      key: "change-project-error",
    });
  }
}
