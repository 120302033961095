import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GetAgentQuery, Role } from "../../../graphql/generated";
import { AgentStatus } from "../../../types/agents";
import { getAgentPlatformIcon } from "../../../utils/platform-display-data";
import { upgradeAgent } from "../../../utils/rest/upgrade-agent";
import { ArrowUpIcon, LockIcon } from "../../Icons";
import { LinkButton } from "../../LinkButton";
import { RBACWrapper } from "../../RBACWrapper/RBACWrapper";
import { LabelBlock, renderAgentDate, renderAgentStatus } from "../utils";
import styles from "./agent-table.module.scss";

type AgentTableAgent = NonNullable<GetAgentQuery["agent"]>;
interface AgentTableProps {
  agent: AgentTableAgent;
}

export const AgentTable: React.FC<AgentTableProps> = ({ agent }) => {
  const navigate = useNavigate();
  function renderTable(agent: AgentTableAgent): JSX.Element {
    const { status, labels, connectedAt, disconnectedAt, id } = agent;

    function renderConnectedAtRow(): JSX.Element {
      if (status === AgentStatus.CONNECTED) {
        const connectedEl = renderAgentDate(connectedAt);
        return renderRow("Connected", <Typography>{connectedEl}</Typography>);
      }

      const disconnectedEl = renderAgentDate(disconnectedAt);
      return renderRow(
        "Disconnected",
        <Typography>{disconnectedEl}</Typography>,
      );
    }

    function fillIn(s: any): string {
      return isEmpty(s) ? "-" : s;
    }
    return (
      <Stack direction={"row"}>
        <Stack minWidth={"400px"}>
          {renderRow(
            "Name",
            <Tooltip title={agent.name} placement="top">
              <Stack
                direction={"row"}
                alignItems={"center"}
                marginRight={"3rem"}
              >
                {agent.platform && getAgentPlatformIcon(agent.platform) && (
                  <Box className={styles["platform-icon"]}>
                    {getAgentPlatformIcon(agent.platform)}
                  </Box>
                )}

                <Typography maxWidth={"200px"} noWrap={true}>
                  {fillIn(agent.name)}
                </Typography>
              </Stack>
            </Tooltip>,
          )}
          {renderRow(
            "Status",
            <Stack className="status-chip" display={"inline"}>
              {renderAgentStatus(agent.status)}
            </Stack>,
          )}
          {renderTypeRow("Type", agent)}
          {renderVersionRow("Version", agent)}
          {renderRowString("Host Name", fillIn(agent.hostName))}
          {renderRowString(
            "Platform",
            fillIn(`${agent.platform} ${agent.architecture}`),
          )}
          {renderRowString("Operating System", fillIn(agent.operatingSystem))}
        </Stack>
        <Stack>
          {renderRowString("Agent ID", fillIn(agent.id))}
          {renderRowString("Remote Address", fillIn(agent.remoteAddress))}
          {renderRowString("MAC Address", fillIn(agent.macAddress))}
          {renderConfigLink(
            "Configuration",
            fillIn(agent.configurationResource?.metadata.name),
            () =>
              navigate(
                `/configurations/${agent.configurationResource?.metadata.name}`,
              ),
          )}
          {renderConnectedAtRow()}
          {renderRow("Labels", <LabelBlock labels={labels} agentId={id} />)}
          {agent.configEncryption && (
            <Tooltip
              title={
                "This agent reported an encryption key that Bindplane has used to encrypt sensitive configuration values"
              }
            >
              {renderRow(
                "Credentials Encrypted",
                <LockIcon className={styles["encryption-icon"]} />,
              )}
            </Tooltip>
          )}
        </Stack>
      </Stack>
    );
  }
  return <>{agent == null ? null : renderTable(agent)}</>;
};

const agentTypeDisplayNames: { [typeName: string]: string } = {
  otelcontribcol: "OpenTelemetry Collector Contrib",
  "com.observiq.collector": "Bindplane Agent",
  "bindplane-otel-collector": "Bindplane OTel Collector",
};

function renderTypeRow(key: string, agent: AgentTableAgent): JSX.Element {
  const text = agentTypeDisplayNames[agent.type] ?? agent.type;
  return renderRow(key, <Typography>{text}</Typography>);
}

function renderVersionRow(key: string, agent: AgentTableAgent): JSX.Element {
  async function handleUpgrade() {
    if (!agent.upgradeAvailable) {
      return;
    }

    try {
      await upgradeAgent(agent.id, agent.upgradeAvailable);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Stack direction={"row"}>
        <Typography classes={{ root: styles["key-column"] }}>{key}</Typography>
        <Typography data-testid="agent-details-version">
          {agent.version}
        </Typography>
        {agent.upgradeAvailable &&
          agent.status !== AgentStatus.DISCONNECTED && (
            <RBACWrapper requiredRole={Role.User}>
              <Button
                endIcon={<ArrowUpIcon width={"20px"} />}
                size="small"
                classes={{ root: styles["upgrade-button"] }}
                disabled={agent.status === AgentStatus.UPGRADING}
                onClick={() => handleUpgrade()}
              >
                Upgrade to {agent.upgradeAvailable}
              </Button>
            </RBACWrapper>
          )}
      </Stack>
    </>
  );
}

function renderRow(key: string, element: JSX.Element): JSX.Element {
  return (
    <Stack direction={"row"}>
      <Typography classes={{ root: styles["key-column"] }}>{key}</Typography>
      <div
        data-testid={`agent-details-${key.toLowerCase().replaceAll(" ", "-")}`}
      >
        {element}
      </div>
    </Stack>
  );
}

function renderRowString(key: string, value: string): JSX.Element {
  return renderRow(key, <Typography>{value}</Typography>);
}

function renderConfigLink(
  key: string,
  value: string,
  onClick: () => void,
): JSX.Element {
  if (value === "-") {
    return renderRow(key, <Typography>{value}</Typography>);
  }
  return renderRow(
    key,
    <LinkButton
      dataTestID={`agent-configuration-${value}`}
      buttonClassName={styles["config-link"]}
      onClick={onClick}
    >
      {value}
    </LinkButton>,
  );
}
