import { createClient, Client } from "graphql-ws";
import { getIsDev } from "./utils/get-is-dev";

let wsClient: Client | undefined;

export type ConnectionHandler = () => void;

type OnConnectedHandlerMap = {
  [key: number]: ConnectionHandler;
};

// connectionHandlersIdx tracks the current index for registering connection handlers.
let connectionHandlersIdx = 0;
// onConnectedHandlers holds all currently registered OnConnected handlers
let onConnectedHandlers: OnConnectedHandlerMap = {};

/**
 * registerConnectionHandler registers a handler to be called whenever the GraphQL websocket
 * connects.
 *
 * @param handler A connection handler function that will be called when the websocket reconnects
 * @returns a number that can be used with unregisterConnectionHandler to unregister the handler
 */
export function registerConnectionHandler(handler: ConnectionHandler): number {
  onConnectedHandlers[connectionHandlersIdx] = handler;
  return connectionHandlersIdx++;
}

/**
 * unregisterConnectionHandler unregisters a connection handler that was registered with
 * registerConnectionHandler, using the ID returned from the call to registerConnectionHandler.
 *
 * @param id an ID returned from registerConnectionHandler/
 */
export function unregisterConnectionHandler(id: number) {
  delete onConnectedHandlers[id];
}

/**
 * getWebsocketClient returns the global GraphQL websocket client, creating it if it does not already exist.
 * The URL is hardcoded to `/v1/graphql` because that's our global endpoint for the GraphQL API.
 */
export function getWebsocketClient(): Client {
  if (wsClient) {
    return wsClient;
  }

  const ws = window.location.protocol === "https:" ? "wss:" : "ws:";
  // In development, we need to connect to the websocket on port 3001 instead of the proxied port 3000.
  const url = getIsDev()
    ? new URL(
        `${ws}//${window.location.host.replace("3000", "3001")}/v1/graphql`,
      )
    : new URL(`${ws}//${window.location.host}/v1/graphql`);

  wsClient = createClient({
    url: url.href,
    shouldRetry: () => {
      return true;
    },
    on: {
      connected() {
        // When we connect, we should run all the onConnected handlers.
        for (let handler of Object.values(onConnectedHandlers)) {
          handler();
        }
      },
    },
  });

  return wsClient;
}
