/**
 * Returns true if the current environment is development.
 *
 * @returns true if the current environment is development.
 */
export function getIsDev(): boolean {
  try {
    return __IS_DEV__;
  } catch (err) {
    return false;
  }
}
