import { Box } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import React, { ChangeEvent, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { classes } from "../../utils/styles";
import { ExpandButton } from "../ExpandButton";
import styles from "./yaml-editor.module.scss";
import "@uiw/react-textarea-code-editor/dist.css";

interface YamlEditorProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  readOnly?: boolean;
  limitHeight?: boolean;
  minHeight?: number;
  height?: number;
  onValueChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  actions?: JSX.Element | null;
}
/**
 * YamlEditor is a component that renders a textarea with syntax highlighting.
 * The value is controlled and can be altered with onValueChange prop.
 * If actions prop is defined it will display the actions in the top right corner.
 */
export const YamlEditor: React.FC<YamlEditorProps> = ({
  limitHeight = false,
  readOnly,
  value,
  onValueChange,
  actions,
  minHeight,
  height,
  ...rest
}) => {
  // We are only using light theme right now.  This overrides the styling if a user
  // has dark mode as a browser preference.
  document.documentElement.setAttribute("data-color-mode", "light");

  const ref = useRef<HTMLTextAreaElement | null>(null);

  const [expanded, setExpanded] = useState(false);
  const [expandable, setExpandable] = useState(false);

  useEffect(() => {
    if (ref.current && ref.current.scrollHeight > 500) {
      setExpandable(true);
      return;
    }
    setExpandable(false);
  }, [ref]);

  const codeEditorClasses = [
    styles.editor,
    expanded || !limitHeight || !readOnly ? styles.expanded : undefined,
    !readOnly ? styles["bg-white"] : undefined,
  ];

  if (expanded || !limitHeight || !readOnly) {
    codeEditorClasses.push(styles.expanded);
  }

  return (
    <Box
      className={rest.disabled || readOnly ? styles.container : styles.editable}
      height={height}
      position="relative"
    >
      <CodeEditor
        {...rest}
        data-testid="yaml-editor"
        className={classes(codeEditorClasses)}
        readOnly={readOnly}
        value={value}
        ref={ref}
        language="yaml"
        onChange={onValueChange}
        padding={15}
        minHeight={minHeight}
        style={{
          fontFamily: `"Roboto Mono", "Monaco", "Menlo", "Ubuntu Mono",
  "Consolas", "source-code-pro", monospace`,
          /* minHeight must be close to height so that the CodeEditor div fills the containing Box, 
             but not so close that we always get a scroll bar, even when the CodeEditor doesn't have enough content to fill the Box.
             height - 2 is this threshold
          */
          minHeight: height ? height - 2 : height,
        }}
      />

      {actions && (
        <div className={styles.actions} style={{ top: 0 }}>
          {actions}
        </div>
      )}

      {/* Allow expand and collapse in readOnly mode, if height is over 300 and the limitHeight prop was passed */}
      {readOnly && expandable && limitHeight && (
        <ExpandButton
          expanded={expanded}
          onToggleExpanded={() => setExpanded((prev) => !prev)}
        />
      )}
    </Box>
  );
};
