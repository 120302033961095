import { CircularProgress, Stack } from "@mui/material";
import { createBrowserHistory } from "history";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoginError } from "../../components/LoginError/LoginError";
import colors from "../../styles/colors";
import { invitationKey } from "./Auth0Login";

type RedirectResponse = {
  url: string;
};

export const OIDCLogin: React.FC = () => {
  const history = createBrowserHistory();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string | null>(null);

  const invitation = searchParams.get("invitation");
  if (invitation) {
    localStorage.setItem(invitationKey, invitation);
  }

  const getToken = useCallback(async () => {
    try {
      const resp = await fetch("/auth/oidc/login");
      if (!resp.ok) {
        const errorData = await resp.json();
        const errorMessage = errorData.errors?.[0] || "Failed to login";
        setError(errorMessage);
        return;
      }
      const tokenResponse = (await resp.json()) as RedirectResponse;
      history.push(tokenResponse.url);
    } catch (e) {
      console.error("request failed", { e });
      setError("Failed to connect to authentication service");
    }
  }, [history]);
  const handleReturnToLogin = useCallback(async () => {
    localStorage.removeItem("user");
  }, []);

  useEffect(() => {
    if (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
    getToken();
  }, [error, enqueueSnackbar, getToken]);

  return (
    <Stack
      height="100vh"
      alignItems="center"
      justifyContent={"center"}
      bgcolor={colors.backgroundWhite}
    >
      {error ? (
        <LoginError error={error} onClick={handleReturnToLogin} />
      ) : (
        <CircularProgress size={100} />
      )}
    </Stack>
  );
};
